<template>
  <button
    v-if="hasConnection === false"
    class="rounded py-3 px-5 text-flame-white font-bold bg-green-700 hover:bg-green-800"
    @click="createConnection()"
  >
    <span>Connect to QuickBooks</span>
  </button>
  <button
    v-else-if="hasConnection === true"
    class="rounded py-3 px-5 text-flame-white font-bold bg-deep-red-800"
    @click="clearConnection()"
  >
    <span>Disconnect QuickBooks</span>
  </button>
  <span v-else> Loading... </span>
</template>

<script>
export default {
  name: 'Quickbooks',
  methods: {
    async clearConnection() {
      this.hasConnection = null
      const { payload } = await this.$store.dispatch('ExportToken/clearConnection', {
        companyId: this.defaultedCompanyId,
        type: 'quickbooks'
      })
      if (payload) {
        this.hasConnection = false
      }
    },
    async createConnection() {
      this.hasConnection = null
      const { payload } = await this.$store.dispatch('ExportToken/createConnection', {
        type: 'quickbooks'
      })
      if (payload && payload.authUrl) {
        window.location.href = payload.authUrl
      }
    },
    async refreshToken() {
      await this.$store.dispatch('ExportToken/refreshToken')
    },
    async getExistingConnection() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'export_token/getActiveConnection',
        data: {
          companyId: this.defaultedCompanyId
        }
      })

      this.hasConnection = !!(
        payload &&
        payload.connection &&
        payload.connection.export_token_time_expiry
      )
    }
  },
  mounted() {
    this.getExistingConnection()
  },
  computed: {
    defaultedCompanyId() {
      return this.$store.state.session.company.company_id
    }
  },
  data() {
    return {
      hasConnection: null
    }
  }
}
</script>
